export const DateInput = ({label, value, onChange}) => {
    const pad = (n) => String(n).padStart(2, "0");
    const val = value != null ? `${value.getFullYear()}-${pad(value.getMonth() + 1)}-${pad(value.getDate())}` : "";
    const change = (e) => onChange(new Date(e.target.value));

    return <label className="date-input">
             {label}
             <input type="date" value={val} onChange={change} />
           </label>;
};
