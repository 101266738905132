import { concat } from "./support.js";

// Use apply as reducer and dispatch functions as actions
export const reduce = (state, action) => action (state);

const Source = (url) => ({url, enabled: true});

export const initState = {
    sources: {
        "AfriNIC": Source("https://lirportal.ripe.net/certification/content/static/statistics/afrinic.tal.txt"),
        "APNIC": Source("https://lirportal.ripe.net/certification/content/static/statistics/apnic.tal.txt"),
        "ARIN": Source("https://lirportal.ripe.net/certification/content/static/statistics/arin.tal.txt"),
        "LACNIC": Source("https://lirportal.ripe.net/certification/content/static/statistics/lacnic.tal.txt"),
        "RIPE NCC": Source("https://lirportal.ripe.net/certification/content/static/statistics/ripencc.tal.txt"),
    },
    period: {
        from: null,
        until: null,
    },
    loading: false,
    sourceData: {},
    data: [],
};

const constant = (x) => () => x;

const filterData = (sourceData, sources, {from, until}) => {
    const filters = [
        from != null ? (({date}) => date >= from) : constant(true),
        until != null ? (({date}) => date <= until) : constant(true),
    ];
    const predicate = filters.reduce((filter, acc) => (x) => acc (x) && filter (x), constant(true));

    return concat(
        Object.entries(sourceData).map(
            ([source, data]) => sources[source].enabled ? data.filter(predicate) : []
        )
    );
};

const initPeriod = (data) => {
    return data.reduce(
        (acc, {date}) => ({
            from: acc.from < date ? acc.from : date,
            until: acc.until > date ? acc.until : date
        }),
        { from: new Date(), until: null }
    );
};

export const Actions = {
    // Data loading
    loading: (state) => ({...state, loading: true}),
    sourceDataLoaded: (sourceData) => (state) => {
        const data = filterData(sourceData, state.sources, state.period);
        return {
            ...state,
            loading: false,
            sourceData,
            error: null,
            data,
            period: initPeriod(data),
        };
    },
    reportError: (error) => (state) => ({...state, loading: false, error}),

    toggleSource: (source, value) => (state) => {
        const sources = {
            ...state.sources,
            [source]: {
                ...state.sources[source],
                enabled: value
            },
        };
        return {
            ...state,
            sources,
            data: filterData(state.sourceData, sources, state.period),
        };
    },

    filterFrom: (from) => (state) => {
        const period = { ...state.period, from };
        return {
            ...state,
            period,
            data: filterData(state.sourceData, state.sources, period),
        };
    },

    filterUntil: (until) => (state) => {
        const period = { ...state.period, until };
        return {
            ...state,
            period,
            data: filterData(state.sourceData, state.sources, period),
        };
    },
};
