import { VegaLite } from "react-vega";

/**
 * Render a graph that plots the 'date' and '{attr}' of each object in the data
 * array.
 */
export const Graph = ({title, attr, attrFriendly, data}) => {
    const spec = {
        "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
        description: title,
        width: 1024,
        data: { name: "records" },
        transform: [{
            window: [
            {
                field: attr,
                op: "mean",
                as: `mean_${attr}`,
            }
            ],
            // 30 day moving average
            frame: [-30, 0],
            groupby: ["source"]
        }],
        encoding: {
            x: { field: "date", type: "temporal", title: "Date"},
            color: { field: "source", type: "nominal", title: "Trust Anchor" },
            y: { field: `mean_${attr}`, type: "quantitative", title: attrFriendly },
        },
        mark: "line",
    };

    return (
        <section className="card graph">
        <h2>{ title }</h2>
        <VegaLite data={ { records: data } }
                    spec={ spec }
                    actions={ {export: true, source: false, compiled: false, editor: false} }
                    renderer="svg"
            />
        </section>
    );
}
